@import "~@riophae/vue-treeselect/dist/vue-treeselect.css";

/* Info bulle */
.helper {
    position: relative;
}

.el-form-item__content .helper__button {
    position: absolute;
    top: 10px;
    right: 12px;
}

.helper__button {
    background: none;
}

.helper__button i {
    font-size: 20px;
}

.popover {
    z-index: 10000;
}

.popover-disconnect {
    border: 1px solid #cccccc;
    background-color: white;
    padding: 5px;
}

/* End info bulle */

/*.affix .sidebar,*/
/*.affix .sidebar--closable {*/
/*margin-bottom: 60px;*/
/*}*/

.content--sidebar {
    width: 100%;
    float: right;
    background-color: #fafafa;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1px solid #e2e2e2;

    //-webkit-transition: all 0.5s;
    //-moz-transition: all 0.5s;
    //-ms-transition: all 0.5s;
    //-o-transition: all 0.5s;
    //transition: all 0.5s;

    overflow-y: auto;
    overflow-x: hidden;
    z-index: 180;
}

.content--sidebar.sidebar--hide {
    right: -100%;
}

.content--sidebar .sidebar {
    padding: 25px 0 30px;
    background-color: #fafafa;
    position: absolute;
    height: calc(100% - 60px);

    > div {
        position: fixed;
    }
}

.content--sidebar .v-select {
    margin-bottom: 10px;
}

.content--sidebar .v-select .open-indicator {
    right: 30px;
}

.content--sidebar .v-select.single .selected-tag {
    position: absolute;
    padding: 1px .5em;
    width: calc(100% - 60px);
}

.content--sidebar .v-select .dropdown-toggle .clear {
    bottom: 6px;
    right: 50px;
}

.content--sidebar .v-select .spinner {
    right: 45px;
    border-radius: 50% !important;
}

.v-select .dropdown-toggle {
    padding: 0 !important;
    height: 60px;
}

.v-select .dropdown-toggle ul {
    z-index: 10000;
}

.v-select .vs__selected-options {
    align-items: center;
}

.v-select input[type="search"], .v-select input[type="search"]:focus {
    margin: 0 !important;
}

select.assignment-select {
    height: 300px;
    padding: 0 5px;
    margin-top: 10px;
    border: none;
}

select.assignment-select option {
    height: 30px;
    padding: 6px 12px;
    margin: 5px 0;
    background-color: white;
    border: 1px solid #cccccc;
    color: #333333;
}

.content--sidebar .v-select input[type=search] {
    width: 100%;
    padding: 0 1em;
}

.overlay-edit {
    background-color: #000000;
    z-index: 1100;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    opacity: 0;
    visibility: hidden;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.overlay-edit--active {
    opacity: 0.5;
    visibility: visible;
}

/* style sidebar scroll */
.affix-wrapper::-webkit-scrollbar-track {
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
    border-radius: 10px;
    background-color: #fafafa;
}

.affix-wrapper::-webkit-scrollbar {
    width: 12px;
    background-color: #fafafa;
}

.affix-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
    background-color: #555;
}

/* end style sidebar scroll */

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    opacity: 0.3;
}

.sidebar {
    width: 100%;
    min-height: 100%;
}

.sidebar-action {
    background-color: #fff;
    position: fixed;
    top: 42px;
    width: 31%;
    right: -31%;
    height: 55px;
    border-bottom: 1px solid #e2e2e2;
    border-left: 1px solid #e2e2e2;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.sidebar--closable {
    right: -100%;
    z-index: 1200;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.sidebar--closable.sidebar--open .sidebar-action {
    right: 0;
}

.sidebar--closable.sidebar--open {
    right: 0;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.sidebar--closable .close-sidebar {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 30px;
    cursor: pointer;
    z-index: 10;
}

.sidebar__container {
    padding: 0 20px;
}

.sidebar-actions {
    position: absolute;
    top: 2px;
    width: 100%;
    background: #fff;
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e2e2;
}

.sidebar-actions + .sidebar__container {
    padding-top: 25px;
}

.sidebar__title {
    font-size: 15px;
    margin-bottom: 20px;
    margin-top: 18px;
    color: #d84a38;
}

.sidebar__subtitle {
    font-size: 10px;
    margin-top: -12px;
    margin-bottom: 10px;
}

.sidebar__separator {
    margin-bottom: 30px;
    border-bottom: 1px solid #e2e2e2;;
}

.sidebar__list {
    padding: 0;
}

.sidebar__list .vddl-list {
    padding: 15px 0;
}

.sidebar__list.ashcan {
    -webkit-border-top-left-radius: 10px !important;
    -webkit-border-top-right-radius: 10px !important;
    -webkit-border-bottom-right-radius: 10px !important;
    -webkit-border-bottom-left-radius: 10px !important;
    -moz-border-radius-topleft: 10px !important;
    -moz-border-radius-topright: 10px !important;
    -moz-border-radius-bottomright: 10px !important;
    -moz-border-radius-bottomleft: 10px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.sidebar__list li {
    list-style: none;
}

.sidebar__list .empty-text {
    text-align: center;
    padding: 30px 0;
    color: #999999;
}

.sidebar__list--empty {
    border: 1px dashed #999999;
}

.sidebar__list li .employee {
    padding: 6px 12px;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    color: #333333;
    margin-bottom: 10px;
}

.sidebar__list li .employee.added {
    background-color: #eeeeee;
    color: #888888;
}

.sidebar__list-risk {
    margin: 0 0 15px;
    padding: 0;
}

.sidebar__list-condition {
    padding: 0;
    margin: 0 0 20px;
}

.sidebar__list-risk li,
.sidebar__list-condition li {
    list-style: none;
    margin-bottom: 6px;
}

.sidebar__list-risk li .helper__button,
.sidebar__list-condition li .helper__button {
    line-height: 1;
    height: 15px;
    position: relative;
    top: -5px;
}

.sidebar__list-risk input,
.sidebar__list-condition input {
    display: inline;
    position: relative;
    top: 2px;
}

.sidebar__list-risk label,
.sidebar__list-condition label {
    display: inline;
    font-size: 12px;
}

.sidebar__list-employees .vddl-placeholder {
    display: none;
}

.sidebar__alert-block {
    background: url("../../images/warning.png") no-repeat bottom right #852b99;
    color: #FFFFFF;
    padding: 20px;
    margin: 20px 0;
}

.sidebar__alert-block__list-risk {
    font-weight: 800;
    margin-top: 15px;
}

.sidebar__list__action {
    margin-top: 10px;
}

.sidebar__action {
    border-bottom: 1px solid #e2e2e2;
    text-align: left;
    padding-left: 20px;
    background-color: #EFEFEF;
}

.sidebar__action:hover {
    background-color: #d84a38;
}

.sidebar__action a {
    color: black;
    text-decoration: none;
    display: block;
    padding: 10px 0;
}

.sidebar__action:hover a {
    color: #ffffff;
}

.sidebar__action a{
    i,svg {
        margin-right: 5px;
        position: relative;
        top: -1px;
    }
}

.sidebar__action a > * {
    vertical-align: middle;
}

/* Modal */


/* END Modal */

input[type='text'],
input[type='date'] {
    width: 100%;
    padding: 6px 12px;
    //border: 1px solid #cccccc;
    //margin-bottom: 10px;
}

select {
    width: 100%;
    padding: 5px 12px;
    border: 1px solid #cccccc;
    margin-bottom: 10px;
}

textarea {
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #cccccc;
    margin-bottom: 10px;
    overflow: hidden;
}

select {
    border: 1px solid #cccccc;
}

input[type='submit'] {
    background-color: #d84339;
    border: none;
    color: #FFFFFF;
    padding: 7px 15px 8px;
    margin-top: 10px;
}

.ui.fluid.search {
    margin-bottom: 10px;
}

.btn--add {
    cursor: pointer;
    float: right;
    /*margin-top: 20px;*/
    /*padding: 6px 25px;*/
    /*border-top: 1px solid #cccccc;*/
    /*border-bottom: 1px solid #cccccc;*/
    /*background-color: #ffffff;*/
    color: #333333;
}

.btn--add i {
    position: relative;
    top: 2px;
    margin-right: 2px;
}

.input-left {
    width: 48%;
    margin-right: 2%;
    float: left;
}

.input-left input {
    width: 100%;
}

.input-right {
    width: 48%;
    margin-left: 2%;
    float: right;
}

.input-right input,
.input-right select {
    width: 100%;
}

.inner-radio {
    margin: 20px 0 20px 20px;
}

.inner-radio .el-radio__input {
    margin-left: 10px;
}

input[type='submit']:focus {
    outline: none;
}

/* Error form */
.error-form {
    font-size: 12px;
    color: #d84a38;
    margin: 5px 0;
}

/* End Error form*/

.date-container input {
    background: url("#/images/calendar.png") no-repeat right 10px center #FFFFFF;
}

.date-container .vdp-datepicker {
    position: static;
}

.date-container .vdp-datepicker__calendar {
    width: 100%;
    top: 100px;
    left: 0;
}

.date-container .vdp-datepicker__calendar > header {
    width: 88%;
    left: 6%;
    z-index: 10;
    position: relative;
    background-color: #FFFFFF;
    padding: 0 10px;
}

.date-container .vdp-datepicker__calendar > div {
    width: 88%;
    left: 6%;
    position: relative;
    background-color: #FFFFFF;
    padding: 10px;

    -webkit-border-top-left-radius: 3px !important;
    -webkit-border-top-right-radius: 3px !important;
    -webkit-border-bottom-right-radius: 3px !important;
    -webkit-border-bottom-left-radius: 3px !important;
    -moz-border-radius-topleft: 3px !important;
    -moz-border-radius-topright: 3px !important;
    -moz-border-radius-bottomright: 3px !important;
    -moz-border-radius-bottomleft: 3px !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.vdp-datepicker__calendar > span,
.date-container .vdp-datepicker__calendar > header > span {
    position: relative;
    background-color: #FFFFFF;
}

.date-container .vdp-datepicker__calendar:before {
    background-color: #000000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    content: ' ';
}

.date-container .vdp-datepicker input[readonly] {
    cursor: text;
    background-color: #ffffff !important;
}

.section-dropdown {
    padding-bottom: 5px;
}

.one-dropdown {
    margin-top: -1px;
}

.one-dropdown__title {
    border-bottom: 1px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: #FFFFFF;
    cursor: pointer;
}

.one-dropdown__title .remove {
    float: right;
    margin-top: 1px;
}

.one-dropdown__content,
.assignment__content {
    height: 0;
    opacity: 0;
    overflow: hidden;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.assignment__content .sidebar__list {
    clear: both;
}

.one-dropdown--active .one-dropdown__title,
.assignment--active .assignment__title {
    background-color: #e2e2e2;
}

.one-dropdown--active .one-dropdown__content {
    padding-top: 7px;
    padding-bottom: 20px;
    height: auto;
    opacity: 1;
    overflow: visible;
    background-color: #ffffff;
    border-bottom: 1px solid #e2e2e2;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.one-contract.one-dropdown--active .one-dropdown__content {
    height: 240px;
}

.one-contract .one-dropdown__title,
.assignment-contract__title {
    background-color: #fff3f3;
}

.one-job .one-dropdown__title,
.assignment-job__title {
    background-color: #fdfdf9;
}

.assignment--active .assignment__content {
    padding-top: 7px;
    padding-bottom: 7px;
    height: 100px;
    opacity: 1;
    overflow: visible;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.assignment__title {
    background-color: #FFFFFF;
    border-bottom: 1px solid #e2e2e2;
    border-top: 1px solid #e2e2e2;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 15px;
    cursor: pointer;
}

.assignment__content .informations {
    color: #aaa;
}

.content--view .open > .dropdown-menu {
    right: 0;
    left: auto;
    z-index: 1200;
}

.content--view h1 {
    text-align: center;
    font-size: 18px;
}

.content--view h2 {
    text-align: center;
    font-size: 13px;
    margin: 0 0 20px;
}


.vddl-draggable, .vddl-list {
    position: relative;
}

.vddl-draggable[draggable=true] {
    cursor: move;
}

.vddl-list {
    padding-left: 0;
    min-height: 40px
}

.vddl-dragging {
    opacity: .7
}

.vddl-dragging-source {
    display: none
}

.panel {
    border-radius: 8px;
    border: 1px solid #eee;
    margin-bottom: 65px;
    height: auto;
}

.panel--close .panel__body {
    overflow: hidden;
    height: 0;
    min-height: 0;
    display: none;
}

.panel p {
    display: inline-block;
    margin: 0
}

.panel__heading {
    height: 40px;
    line-height: 37px;
    border-bottom: 1px solid #eee;
    background: #f5f5f5;
    color: #FFF;
    position: relative;
}

.panel__heading h3 {
    width: 80%;
    height: 40px;
    float: left;
    font-size: 18px;
    padding: 0 15px;
    line-height: 37px;
    font-weight: 400 !important;
    margin: 0;
}

.panel__heading h3 {
    i, svg {
        cursor: pointer;
    }
}

.panel__action {
    float: right;
    width: 160px;
    cursor: pointer;
    text-align: right;
    position: absolute;
    right: 0;
}

.panel__action ul {
    padding: 0;
}

.panel__action ul li {
    list-style: none;
    padding-right: 15px;
}

.panel__action button,
.panel__action button:hover {
    color: #FFFFFF;
    border: none;
    padding-right: 15px;
    padding-left: 15px;
    height: 100%;
}

.panel__action i {
    position: relative;
    font-size: 16px;
    top: 3px;
    margin-right: 5px;
}

.panel__action .dropdown-menu {
    right: 0;
}

.panel__action .dropdown-menu li:hover {
    background-color: #eeeeee;
}

.dropdown-menu li.disable-action,
.dropdown-menu li.disable-action:hover {
    background-color: #eeeeee;
}

.dropdown-menu li.disable-action a {
    color: #999;
    cursor: default;
}

.panel__body {
    /*background: #fff;*/
    min-height: 40px
}

.panel__body > .panel {
    margin: 10px
}

.panel__body p {
    width: 100%;
    padding: 0 15px;
    font-size: 14px;
    line-height: 20px;
    color: #666
}

.panel__body--list {
    background: #fff
}

.panel__body--item, .panel__body .panel__placeholder {
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    /*border-bottom: 1px solid #eee;*/
    /*padding: 0 15px;*/
    /*background: #fff;*/
    box-sizing: border-box
}

.panel__body--item.no-padding-left {
    padding-left: 0
}

.panel__body--item:last-child {
    border-bottom: none
}

.panel__body--item > .panel {
    margin: 15px;
}

.panel__body--item > .panel--enterprise {
    margin: 15px 0;
}

.panel .panel__placeholder {
    background: #f5f5f5
}

.panel.panel--info .panel__body {
    padding: 25px
}

.nodrag {
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex
}

.panel__body--list.padding {
    padding: 7px
}

.panel__body--list.padding:after {
    content: "";
    clear: both;
    display: block;
    width: 100%;
    height: 0;
    visibility: hidden
}

.vddl-placeholder {
    width: 100%;
    min-height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
    padding: 0 15px;
    background: #f5f5f5;
    color: #6e1880;
}

.new-elements .button {
    border: 1px solid #42b983;
    text-align: center;
    height: 40px;
    border-radius: 4px;
    line-height: 40px;
    background-color: #42b983;
    color: #fff;
    cursor: move;
    font-size: 14px
}

.new-elements .vddl-dragging-source {
    display: block
}

.disable-element .button {
    background-color: #e32822;
    border: 1px solid #e32822;
    cursor: pointer
}

.selected-item .panel__body {
    line-height: 40px
}

.ashcan .vddl-placeholder {
    display: none
}

.simple .vddl-placeholder {
    line-height: 40px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    padding-left: 15px
}

.red {
    color: red
}

.mutations {
    text-align: center
}

.mutations h3 {
    font-size: 16px;
    margin: 0
}

.mutations p {
    font-size: 14px;
    line-height: 20px;
    color: #666
}

.informations {
    color: #852b99;
    margin-bottom: 5px;
    float: left;
}

.show-employee {
    float: right;
}

.informations svg {
    font-size: 16px;
    position: relative;
    display: inline-block;
    margin-right: 5px;
}

.last-element {
    background-color: #ffffff;
    padding: 0 15px;
}

.panel__body--item > .last-element {
    border-bottom: 1px solid #cccccc;
}

.panel__body--item:last-child > .last-element {
    border: none;
}

.employee-manager {
    clear: both;
}

/* Color settings */
.employee-manager > .panel__body--item > .panel > .panel__heading--unit {
    background-color: #4b8df8;
    border-bottom: 1px solid #b3d0f6;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.employee-manager > .panel__body--item > .panel--unit {
    border: 1px solid #b3d0f6;
    background-color: #f1f2f7;
    margin: 15px 0;
}

.employee-manager > .panel__body--item > .panel > .panel__heading--unit .btn--unit {
    background-color: #4b8df8;
}

.employee-manager > .panel__body--item > .panel > .panel__heading--unit .btn--unit:hover {
    background-color: #4279d2 !important;
    color: #ffffff !important;
}

.employee-manager > .panel__body--item > .panel--unit > .panel__heading .dropdown-menu {
    color: #4b8df8;
}

.panel__heading--unit {
    background-color: #35aa47;
    border-bottom: 1px solid #35aa47;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.panel--unit {
    border: 1px solid #35aa47;
    background-color: #f7fafa;
}

.btn--unit {
    background-color: #35aa47;
}

.btn--unit:hover {
    background-color: #2d8f3c !important;
    color: #ffffff !important;
}

.panel--unit .dropdown-menu {
    color: #35aa47;
}

.panel__heading--job {
    background-color: #ffb848;
    border-bottom: 1px solid #ffb848;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.panel--job {
    border: 1px solid #ffb848;
    background-color: #fdfdf9;
}

.btn--job {
    background-color: #ffb848;
}

.btn--job:hover {
    background-color: #d19536 !important;
    color: #ffffff !important;
}

.panel--job .dropdown-menu {
    color: #ffb848;
}

/* Color settings selected stats */
.panel--selected-stats.employee-manager > .panel__body--item > .panel > .panel__heading--unit {
    background-color: #ffffff;
    color: #4b8df8;
}

.panel--selected-stats.employee-manager > .panel__body--item > .panel > .panel__heading--unit .btn--unit {
    background-color: #ffffff;
    color: #4b8df8;
}

.panel--selected-stats.employee-manager > .selected > .panel > .panel__heading .btn--unit {
    background-color: #4b8df8;
    color: #ffffff;
}

.panel--selected-stats.employee-manager > .panel__body--item > .panel > .panel__heading--unit > .panel__action .dropdown-menu {
    color: #4b8df8;
}

.panel--selected-stats.employee-manager > .panel__body--item > .panel > .panel__body > .selected > .last-element {
    background-color: #4b8df8;
    color: #ffffff;
}

.panel--selected-stats .panel__heading--unit {
    background-color: #ffffff;
    color: #35aa47;
}

.panel--selected-stats .selected > .panel--unit > .panel__heading--unit {
    background-color: #35aa47;
    color: #ffffff;
}

.panel--selected-stats .btn--unit {
    background-color: #ffffff;
    color: #35aa47;
}

.panel--selected-stats .selected > .panel > .panel__heading .btn--unit {
    background-color: #35aa47;
    color: #ffffff;
}

.panel--selected-stats .panel__heading--unit .panel__action .dropdown-menu {
    color: #35aa47;
}

.panel--selected-stats .panel--unit > .panel__body > .selected > .last-element {
    background-color: #35aa47;
    color: #ffffff;
}

.panel--selected-stats .panel__heading--job {
    background-color: #ffffff;
    color: #ffb848;
}

.panel--selected-stats .selected > .panel--job > .panel__heading--job {
    background-color: #ffb848;
    color: #ffffff;
}

.panel--selected-stats .btn--job {
    background-color: #ffffff;
    color: #ffb848;
}

.panel--selected-stats .selected > .panel > .panel__heading .btn--job {
    background-color: #ffb848;
    color: #ffffff;
}

.panel--selected-stats .panel__heading--job .panel__action .dropdown-menu {
    color: #ffb848;
}

.panel--selected-stats .panel--job > .panel__body > .selected > .last-element {
    background-color: #ffb848;
    color: #ffffff;
}



/* Table Recap */
.recap {
    margin-top: 40px;
}

.recap .table thead > tr > th {
    padding: 15px 0;
}

.recap .table tbody > tr > td {
    vertical-align: middle;
    text-align: center;
}

.recap .table tbody > tr > td.unit-cell {
    background-color: #f9f9f9;
}

/* Select 2 */
.ui.selection.dropdown .menu > .item {
    margin: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    -webkit-box-shadow: none;
}

/* Custom Loader Element */
.loader--custom .el-loading-spinner .el-icon-loading {
    color: #FFFFFF;
    font-size: 40px;
    margin-bottom: 15px;
}

.loader--custom .el-loading-spinner .el-loading-text {
    color: #FFFFFF;
    font-size: 16px;
}

.el-loading-spinner .path {
    stroke: #FFFFFF !important;
}

/* Custom Message Element */
.el-message {
    top: 60px !important;
    padding-right: 60px !important;
}

.el-message i {
    display: inline !important;
}

.el-message__closeBtn {
    top: 50% !important;
    right: 15px !important;
    transform: translateY(-50%) !important;
    font-size: 16px !important;
}

.el-message p {
    margin: 6px !important;
    display: inline;
}

.el-message--success {
    background-color: #f0f9eb !important;
    border: 1px solid #e1f3d8 !important;
}

.el-message--success .el-message__content,
.el-message .el-icon-success {
    color: #67c23a !important;
}

.el-message--error {
    background-color: #fef0f0 !important;
    border: 1px solid #fde2e2 !important;
}

.el-message--error .el-message__content,
.el-message .el-icon-error {
    color: #f56c6c !important;
}

.page-breadcrumb.breadcrumb.affix .pdf-button {
    right: 181px;
}

.recap table.dataTable thead th {
    outline: none !important;
    cursor: default !important;
    padding: 10px !important;
}

.recap table.dataTable thead th:after {
    display: none !important;
}

.recap table.dataTable thead th input,
.recap table.dataTable thead th select {
    margin-top: 10px;
}

.recap table.dataTable thead th select {
    margin-bottom: 0;
}

.recap table thead tr th:nth-child(1) {
    width: 20%;
}

.recap table thead tr th:nth-child(2) {
    width: 10%;
}

.recap table thead tr th:nth-child(3) {
    width: 15%;
}

.recap table thead tr th:nth-child(4) {
    width: 15%;
}

.recap table thead tr th:nth-child(5) {
    width: 5%;
}

.recap table thead tr th:nth-child(6) {
    width: 15%;
}

.recap table thead tr th:nth-child(7) {
    width: 10%;
}

.recap table thead tr th:nth-child(8) {
    width: 10%;
}

.important {
    color: #d84a38
}

/*Medical Visit*/
.visit__head {
    margin-bottom: 30px;
}

.el-select-dropdown {
    z-index: 20000 !important;
}

.el-form-item {
    margin-bottom: 17px;
}

.el-form-item__label {
    line-height: 30px;
}

.el-form-item__content {
    line-height: 25px;
}

//.el-form-item__error {
//    right: 0;
//    top: 19px;
//    left: inherit;
//}

input[readonly], select[readonly], textarea[readonly] {
    background-color: #FFF;
}

.el-input {
    &__icon {
        padding-bottom: 10px;
    }
}

.el-date-editor.el-input {
    width: 100%;
}

.vue-treeselect--single .vue-treeselect__input {
    border: none;
    padding: 0;
}

.summary {
    margin-top: 50px;
}

.recap {
    .el-table__fixed {
        background-color: #ddd;

        thead tr th:nth-child(1) {
            background-color: #ddd;
            border: none;
        }
    }
}

.modal-container{
    .sidebar__alert-block{
        margin: 8px 0;
    }
    .sidebar__alert-block__list-risk{
        margin-top: 8px;
    }
}
.pcs{
svg{
    position: absolute;
    top: 10px;
    right: 5px;
}
}
.sir{
    .el-checkbox{
        margin-bottom: 0;
    }
}
