.page-content {
    position: relative;
    overflow: hidden;
    min-height: calc(100vh - 75px);
}

.affix-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 31%;
    min-width: 200px;
    height: 100%;
    //max-height: calc(100vh - 42px);
    overflow-y: auto;
    overflow-x: hidden;

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.affix-wrapper--hide {
    right: -31%;
}

.affix.affix-wrapper {
    position: fixed;
    z-index: 1110;
    top: 76px;
    width: 26%;
    max-height: calc(100vh - 76px);
}

.content--view {
    float: left;
    width: 65%;
    margin-left: 2%;
    margin-bottom: 70px;

    //-webkit-transition: all 0.5s;
    //-moz-transition: all 0.5s;
    //-ms-transition: all 0.5s;
    //-o-transition: all 0.5s;
    //transition: all 0.5s;
}

.content--view.sidebar--hide {
    width: 96%;
}
